import React from "react";

import Header from "./components/header";
import Footer from "./components/footer";

const Yurpodderjka = () => {
  return (
    <div>
      <Header />
      <main className="bg-gray-200 py-8 space-y-8">
        <div className="mx-auto max-w-6xl container p-8 bg-white space-y-4">
          <h2>Юридическая защита бизнеса</h2>
          <div className="news-text">
            <p>
              Большинство партнеров нашего консалтинговое агентства – это
              представители малого и среднего бизнеса, которым нет необходимости
              содержать штатного юриста. Но вместе с тем мы сотрудничаем и с
              крупными компаниями, а в ряде случаев и с частными лицами. Мы
              представляем достаточно большой спектр услуг, в числе которых
              можно выделить следующие.{" "}
            </p>
            <h3>
              Юридическое сопровождение проверок, проводимых
              контрольно-надзорными органами.
            </h3>
            <p>
              Мы предлагаем юридическую поддержку на различных стадиях проверки.
              На стадии подготовки к проведению плановой проверки наши
              специалисты изучат состояние дел у заказчика по проверяемой линии,
              дадут рекомендации по устранению возможных недостатков, окажут
              помощь с составлением необходимой документации.{" "}
            </p>
            <p>
              На стадии осуществления проверки, в том числе и внеплановой,
              юристы проверят документы, служащие основанием для ее проведения,
              будут сопровождать проверяющих лиц, давать необходимые пояснения,
              представлять необходимую документацию, следить за законностью
              предъявляемых требований и точностью формулировок при составлении
              итоговых документов.{" "}
            </p>
            <p>
              Обжалование результатов проверки будет проводиться при наличии
              фактических и процессуальных оснований. Юристы консалтингового
              агентства не только составят необходимые документы, но и будут
              представлять интересы заказчика в административных и судебных
              органах.
            </p>
          </div>
          <h3>Представление интересов в государственных органах.</h3>
          <p>
            Необходимость в данной деятельности зачастую возникает тогда, когда
            в отношении юридического лица, его собственников или сотрудников
            совершены противоправные действия или есть необходимость разрешения
            какой-либо проблемы в чиновничьих кабинетах.{" "}
          </p>
          <p>
            К сожалению, сложность российского законодательства, неоднозначная
            практика правоприменения и уровень профессионализма отдельных
            сотрудников государственных структур не всегда позволяет добиться
            положительного результата.{" "}
          </p>
          <p>
            Сотрудники «КФП-Консалтинг», имеющие опыт работы в государственных
            структурах, возьмут на себя общение с работниками госорганов и будут
            добиваться положительного решения возникшей проблемы всеми
            доступными средствами, вплоть до обжалования действий должностных
            лиц в судебном порядке.
          </p>
          <p>
            У нас есть положительный опыт работы с органами внутренних дел,
            УФАС, военкоматами и другими органами власти, как федеральными, так
            и республиканскими.{" "}
          </p>
          <h3>Взыскание дебиторской задолженности.</h3>
          <p>
            Невозврат денежных средств в современных реалиях является серьезной
            проблемой не только для юридических, но и физических лиц. И самый
            сложный вопрос, который приходится решать, это не получение
            судебного решения, а его исполнение.{" "}
          </p>
          <p>
            К сожалению, при существующей нагрузке в более чем 4000 дел в год на
            каждого сотрудника, судебные приставы просто не в состоянии
            обеспечить качественную работу по каждому исполнительному
            производству.
          </p>
          <p>
            Специалисты «КФП-Консалтинга» включаются в работу на любой стадии –
            от досудебной до исполнительного производства. В ряде случаев
            проблему можно решить в результате переговоров с должником или его
            представителями, в других – в ходе примирительных процедур или
            судебного процесса.{" "}
          </p>
          <p>
            Но, как показывает практика, в 70-80 процентах дел должники начинают
            исполнять решения суда только тогда, когда в процесс вступают
            судебные приставы. Мы давно пришли к выводу, что если есть желание
            помочь фирме или конкретному человеку, то недостаточно подготовить
            необходимые бумаги для ФССП.{" "}
          </p>
          <p>
            Необходимо искать активы должника, выявлять попытки их сокрытия,
            своевременно информировать судебных приставов и добиваться должного
            исполнения ими своих обязанностей. Особенно часто потребность в
            должной работе по вопросу взыскания дебиторской задолженности
            возникает у банков, лизинговых компаний, микрофинансовых организаций
            и коллекторских агентств.{" "}
          </p>
          <p>
            ООО «КФП-Консалтинг» готово к сотрудничеству с любыми организациями.
            Накопленный опыт позволил нашей компании только в текущем году
            вернуть кредиторам средства на сумму свыше 43 миллионов рублей.{" "}
          </p>
          <h3>Юридическое сопровождение текущей хозяйственной деятельности.</h3>
          <p>
            Юридические вопросы возникают в деятельности любой
            предпринимательской структуры, все дело в частоте их возникновения.
            Но если на крупных предприятиях всегда есть штатный юрист, а порой и
            целая юридическая служба, то в малом и среднем бизнесе решение
            юридических проблем в большинстве случаев удел руководителя.
          </p>
          <p>
            И хорошо если у него есть соответствующее образование и навыки.
            Однако даже если они есть, на юридическую деятельность тратится
            время, которое можно было бы использовать с большей
            результативностью. В последнее время набирают популярность
            интернет-ресурсы, на которых можно скачать образец любого
            юридического документа или моментально получить ответ на любой
            вопрос даже не от человека, а чат-бота.
          </p>
          <p>
            Конечно, иногда можно найти решение проблемы и таким образом, но это
            все равно неравноценная замена квалифицированному юристу, ведь его
            обязанностью является разработка правомерного решения того или иного
            вопроса с учетом специфики сложившейся ситуации, а порой и с учетом
            личности конкретного человека.{" "}
          </p>
          <p>
            Юристы «КФП-Консалтинг» на абонентской основе возьмут на себя
            рецензирование и подготовку договоров, разработку правовых
            документов, регламентирующих деятельность компании и ее сотрудников,
            консультирование по всем возникающим правовым вопросам в режиме
            24\7, возьмут на себя представление интересов клиента в органах
            государственной власти, в том числе и судебных, при необходимости
            примут участие в переговорах. При этом стоимость услуг будет гораздо
            ниже затрат на содержание штатного юриста.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Yurpodderjka;
